import { graphql, useStaticQuery } from "gatsby"

export default function useNewsData() {
  const data = useStaticQuery(graphql`
    query getNewsData {
      allMarkdownRemark(
        sort: { order: DESC, fields: frontmatter___date }, 
        filter: {fileAbsolutePath: {regex: "/(news)/.*\\.md$/"}}
      ) {
        edges {
          node {
            id
            frontmatter {
              author
              content
              date(formatString: "MMMM DD YYYY")
              description
              title
              thumbnail {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return data.allMarkdownRemark.edges
}
